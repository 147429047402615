import moment from "moment";
import React from "react";
import { m, titleize, renderColor } from "../../../utils";
import { rgb } from "d3-color";
import { useMutation } from "@apollo/client";
import { ACTIVATE_PERSONAL, DEACTIVATE_PERSONAL } from "./queries";

const Status = ({ user, rideshareData, name, refetch }) => {
  const notConnected = rideshareData[0]?.impliedStatus === "not_connected";
  const activePersonal =
    rideshareData[0]?.impliedStatus === "active" && name == "personal";
  const inactivePersonal =
    (rideshareData[0]?.impliedStatus === "inactive") & (name == "personal");
  var now = moment();
  const isExpired = !notConnected
    ? m(rideshareData[0]?.cookiesExpiryDate).isBefore(m(now))
    : null;
  const setColor = notConnected
    ? rgb(65, 65, 65, 0.5)
    : isExpired
    ? "red"
    : "#3dc700";

  const ableToDeactivate = activePersonal;
  const ableToActivate = inactivePersonal || rideshareData.length == 0;

  const [activateMutation] = useMutation(ACTIVATE_PERSONAL);
  const handleActivate = () => {
    activateMutation({ variables: { userId: user?.id } }).then(
      ({ data: { connectPersonalUseRidesharePlatform } }) => {
        if (connectPersonalUseRidesharePlatform.success) {
          refetch();
          console.log("Activation Succeed!");
        } else {
          console.log("Activation error!");
        }
      }
    );
  };

  const [deactivateMutation] = useMutation(DEACTIVATE_PERSONAL);
  const handleDeactivate = () => {
    deactivateMutation({ variables: { userId: user?.id } }).then(
      ({ data: { disconnectPersonalUseRidesharePlatform } }) => {
        if (disconnectPersonalUseRidesharePlatform.success) {
          refetch();
          console.log("Deactivation Succeed!");
        } else {
          console.log("Deactivation error!");
        }
      }
    );
  };

  return (
    <div
      className="top-container"
      style={{
        justifyContent: name == "personal" ? "space-between" : "flex-start",
      }}
    >
      <div className="status-container">
        {`Autzu Displayed ${titleize(name)} Status`}
        <div
          className={renderColor(
            "Account Status",
            !notConnected
              ? rideshareData[0]?.impliedStatus.toLowerCase()
              : undefined
          )}
          style={{ fontSize: "40px", marginTop: "5px", fontWeight: "500" }}
        >
          {!notConnected
            ? titleize(rideshareData[0]?.impliedStatus)
            : "Not connected"}
          {!notConnected && rideshareData[0]?.lastCheckedAt && (
            <div style={{ opacity: "0.5", color: "#414141", fontSize: "15px" }}>
              Last checked:{" "}
              {m(rideshareData[0]?.lastCheckedAt).format("h:mm A MMM D, YYYY")}
            </div>
          )}
        </div>
      </div>
      {name !== "personal" && (
        <div className="status-container">
          {"Real Uber Status"}
          <div
            className={renderColor(
              "Account Status",
              !notConnected
                ? rideshareData[0]?.impliedStatus.toLowerCase()
                : undefined
            )}
            style={{ fontSize: "40px", marginTop: "5px", fontWeight: "500" }}
          >
            {!notConnected
              ? titleize(rideshareData[0]?.thirdPartyStatus)
              : "Not connected"}
            {!notConnected && rideshareData[0]?.lastCheckedAt && (
              <div
                style={{ opacity: "0.5", color: "#414141", fontSize: "15px" }}
              >
                Last checked:{" "}
                {m(rideshareData[0].lastCheckedAt).format("h:mm A MMM D, YYYY")}
              </div>
            )}
          </div>
        </div>
      )}
      {name == "personal" && (
        <div className="personal-buttons">
          <button
            className={ableToActivate ? "activate" : "activate-disabled"}
            onClick={ableToActivate && handleActivate}
          >
            <div className="activate-content">Activate</div>
          </button>
          <button
            className={ableToDeactivate ? "activate" : "activate-disabled"}
            onClick={ableToDeactivate && handleDeactivate}
          >
            <div className="activate-content">Deactivate</div>
          </button>
        </div>
      )}
    </div>
  );
};

export default Status;
