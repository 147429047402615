import { gql } from "@apollo/client";

const ACTIVATE_PERSONAL = gql`
  mutation connectPersonalUseRidesharePlatform($userId: ID!) {
    connectPersonalUseRidesharePlatform(userId: $userId) {
      success
      error
    }
  }
`;

const DEACTIVATE_PERSONAL = gql`
  mutation disconnectPersonalUseRidesharePlatform($userId: ID!) {
    disconnectPersonalUseRidesharePlatform(userId: $userId) {
      success
      error
    }
  }
`;

export { ACTIVATE_PERSONAL, DEACTIVATE_PERSONAL };
