import React, { Component, useState, useEffect } from "react";
import UserRideshareData from "../../../Queries/UserRideshareData";
import "./rideshare.css";
import { useLocation } from "react-router-dom";
import UsersBlack from "../../../assets/icons/UsersBlack.svg";

const Rideshare = ({ id, refetch }) => {
  const [activeTab, setActiveTab] = useState("uber");
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const pageContent = () => {
    switch (activeTab) {
      case "uber":
        return <UserRideshareData id={id} name={"uber"} />;
      case "personal":
        return <UserRideshareData id={id} name={"personal"} />;
    }
  };
  return (
    <div className="user-details-container">
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className="tab-container">
          <div
            className={`unselectable detail-tab${
              activeTab === "uber" ? " selected" : ""
            }`}
            onClick={() => setActiveTab("uber")}
          >
            Uber
          </div>
        </div>
        <div className="tab-container">
          <div
            className={`unselectable detail-tab${
              activeTab === "personal" ? " selected" : ""
            }`}
            onClick={() => setActiveTab("personal")}
          >
            Personal
          </div>
        </div>
      </div>

      {pageContent()}
    </div>
  );
};

export default Rideshare;
