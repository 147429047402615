import React from "react";
import Details from "./Details";
import Status from "./Status";

const RideshareBody = ({ user, name, refetch }) => {
  const rideshareData = user.ridesharePlatforms;
  const rideshareUber = rideshareData.filter((entry) => entry.name === "uber");
  const rideshareLyft = rideshareData.filter((entry) => entry.name === "lyft");
  const ridesharePersonal = rideshareData.filter(
    (entry) => entry.name === "personal"
  );
  const rideshareContent = () => {
    switch (name) {
      case "uber":
        return rideshareUber;
      case "lyft":
        return rideshareLyft;
      case "personal":
        return ridesharePersonal;
      default:
        return rideshareUber;
    }
  };
  return (
    <div className="user-page__body">
      <Status
        user={user}
        rideshareData={rideshareContent()}
        name={name}
        refetch={refetch}
      />
      {name !== "personal" && (
        <Details user={user} rideshareData={rideshareContent()} />
      )}
    </div>
  );
};

export default RideshareBody;
